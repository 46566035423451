var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addHandle } },
                [_vm._v("新增")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.dataList, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              width: "50",
              align: "center",
              label: "序号"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "reason",
              "header-align": "center",
              align: "center",
              label: "退诊原因"
            }
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              width: "150",
              label: "操作"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.deleteHandle(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }