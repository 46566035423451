<template>
	<div>
		<el-form :inline="true">
			<el-form-item>
				<el-button type="primary" @click="addHandle">新增</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="dataList" border style="width: 100%;">
			<el-table-column type="index" width="50" align="center" label="序号"> </el-table-column>
			<el-table-column prop="reason" header-align="center" align="center" label="退诊原因"></el-table-column>
			<el-table-column header-align="center" align="center" width="150" label="操作">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
export default {
	data () {
		return {
			reason: '',
			dataList: []
		};
	},
	created () {
		this.getData();
	},
	methods: {
		getData () {
			this.$root.request('consultationCloseReasonList', {
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.dataList = data;
				}
			});
		},
		addHandle () {
			this.$prompt('请输入退诊原因', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(({ value }) => {
				if (!value) {
					this.$root.elMsg('请输入退诊原因', 'error');
					return;
				}
				this.$root.request('consultationCloseReasonAdd', {
					reason: value
				}).then((data) => {
					if (data) {
						this.$root.elMsg('操作成功');
						this.getData();
					}
				});
			}).catch(() => {});
		},
		deleteHandle (id) {
			this.$confirm('确认要删除该原因吗？', '提示', {
				distinguishCancelAndClose: true,
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$root.request('consultationCloseReasonDelete', { id }).then((data) => {
					if (data) {
						this.$root.elMsg('操作成功');
						this.getData();
					}
				});
			}).catch(() => {});
		}
	}
};
</script>